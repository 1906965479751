.success-message-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  img {
    width: 75px;
    height: 75px;
  }

  span {
    margin-top: 36px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    font-family: 'Roboto';
    display: flex;
    align-items: center;
    text-align: center;
  }
}
