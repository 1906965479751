@import 'colors';

.detail-header {
  margin: 0;

  & .chevron-icon {
    margin-right: 8px;
  }
}

.backoffice-client-projects-table {
  margin-top: 32px;
  
  & .main {
    color: $main-color;
  }

  & .success {
    color: $table-success-status-color;
  }

  & .danger {
    color: $table-danger-status-color;
  }
}