@import 'colors';
@import './styles/components.scss';

* {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.text-right {
  display: flex;
  justify-content: flex-end;
}

.default-container {
  max-width: 360px;
}
.full-container {
  padding: 48px;
}

.full-width {
  width: 100%;
  max-width: initial;
}
.full-height {
  height: 100%;
}
.flex-center {
  display: flex;
  justify-content: center;
}
.pointer {
  cursor: pointer;
}
