@import 'colors';
@import 'styles/functions.scss';

.layout-container {
  min-height: 100%;

  .left-pane {
    flex: 1;
    min-height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;

    .left-pane-container {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;

      .top-logo {
        position: absolute;
        top: 30px;

        @media screen and (max-height: 480px) {
          display: none;
        }
      }
    }
  }

  .right-pane {
    min-height: 100%;
    background-size: cover;
    background: linear-gradient(180deg, $main-color 0.15%, $footer-gradient-end-color 99.85%);
    .content {
      display: flex;
      height: Calc(100% - 48px);
      margin: 24px;
    }
  }
}
