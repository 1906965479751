@import 'src/colors';

.onboarding-input {
  width: 100%;
  height: 44px;
  margin-top: 0;
  border-radius: 4px;
  padding: 10px 14px;

  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: flex-start;

  border: 1px solid $light-border;
}
