@import 'colors';
@import 'styles/functions.scss';

.back-office-content {
  min-height: calc(100vh - 78px);
  @media screen and (min-width: 745px) {
    padding: 40px 80px 80px 80px;
  }
  @media screen and (max-width: 744px) {
    padding: 10px 10px 150px 10px;
  }
}

.back-office-drawer {
  .close-mark {
    cursor: pointer;
  }

  a {
    gap: 1em;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: 16px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    font-family: 'Roboto';
    text-decoration: none;
    color: $white-color;
    padding-bottom: 4px;

    &.active {
      border-bottom: 1px solid;
    }
  }
}

.header-logo {
  display: flex;
}

.burger-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include respond-above(sm) {
    display: none;
  }
}
