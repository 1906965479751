@import 'colors';

.user-menu-item {
  flex-wrap: nowrap;
  .user-avatar {
    display: flex;
    margin-right: 12px;
  }

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    white-space: nowrap;

    .muted {
      font-size: 10px;
      color: $grayed-text-color;
    }

    // @include respond-below(sm) {
    //   display: none;
    // }
  }

  .user-menu-drop-down-button {
    top: 10px;
    position: relative;
    display: flex;
    margin-left: 12px;
    cursor: pointer;
  }
}
