@import 'colors';

.crm-stats-sync {
  margin-top: 16px;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: $black-color;
}

.backoffice-table {
  & .success {
    color: $table-success-status-color;
  }

  & .danger {
    color: $table-danger-status-color;
  }
}

.backoffice-table button, .table-header {
  padding: 0;
}

.table-header {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 5fr 320px 1fr;
  grid-template-areas: "text search action";
  gap: 16px;

  width: 100%;
  margin-top: 58px;
  margin-bottom: 20px;

  & .text {
    grid-area: text;
  }

  & .search {
    grid-area: search;
  }

  & .action {
    grid-area: action;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 768px) {
  .table-header {
    grid-template-areas: 
      "text action"
      "search search";
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
  }
}
