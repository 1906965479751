@import 'colors';
@import 'styles/functions';

.card {
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.07);

  & .value {
    font-weight: 700;
    font-size: 40px;
    line-height: 47px;

    color: $label-color;

    @include respond-below(sm) {
      font-size: 32px;
    }

    @include respond-below(xs) {
      font-size: 20px;
    }
  }

  & .title,
  .subtitle {
    line-height: 140%;
    color: $gray-color;

    @include respond-below(sm) {
      font-size: 16px;
    }
  }

  & .title {
    font-weight: 400;
  }

  & .subtitle {
    font-size: 16px;
  }
}
