$error-color: #AF3D03;
$black-color: #000000;
$blacky-color: #101828;
$white-color: #FFFFFF;
$whity-color: #F8F9FC;
$main-color: #4E5BA6;
$gray-color: #667085;
$grayed-text-color: #98A2B3;
$grayed-border: #E4E7EC;
$light-border: #D0D5DD;
$gray-background-color: #F5F5F5;

$footer-color: #002564;
$footer-gradient-start-color: #4D5AA3;
$footer-gradient-end-color: #2C335B;

$label-color: #344054;

$border-line-color: #C8CCE5;

$shadow-bg-color: rgba(45, 52, 92, 0.5);
$shadow-box-color: rgba(16, 24, 40, 0.05);

$table-header-color: #F2F4F7;
$table-success-status-color: #129F28;
$table-danger-status-color: #FF7979;