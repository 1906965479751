@import 'colors';

.return-link-container {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;

  &.top-left {
    position: absolute;
    left: 40px;
    top: 40px;
  }

  .return-link {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
    color: $main-color;

    a {
      margin-left: 15px;
      color: $main-color;
      text-decoration: none;
      font-weight: bold;
    }
  }
}
