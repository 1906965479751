@import '../colors';
@import './functions.scss';

// table
.ant-table-wrapper {
  width: 100%;
}

th.ant-table-cell {
  background-color: $table-header-color !important;
  color: $gray-color !important;

  font-size: 12px;
  font-weight: bold !important;
  line-height: 18px;
}

.ant-table-cell:empty:before {
  content: 'Нет данных';
  color: $light-border;
}

.ant-table-column-sorter {
  // disable default sort icons
  display: none;
}

.ant-table-bordered {
  .ant-table-container {
    border: 0 !important;
    .ant-table-content {
      table {
        tbody {
          .ant-table-row {
            td {
              border: 1px dashed #e4e7ec !important;
              &:first-child {
                border-left: 0px !important;
              }
              &:last-child {
                border-right: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

// fonts
h1,
h2,
h3,
h4 {
  &.ant-typography {
    font-family: 'roboto';
    font-weight: bold;
  }
}
h5.ant-typography {
  font-family: 'roboto';
}

h3.ant-typography {
  font-weight: 600 !important;
  font-family: 'Roboto';

  color: $black-color;
}

.ant-typography {
  &.label {
    color: $label-color;
    font-size: 16px;
  }
  &.primary {
    color: $main-color;
    font-weight: 500;
    font-size: 16px;
  }
}

// header

.ant-layout-header {
  display: flex;
  padding: 0px 60px;
  box-shadow: 0px 1px 12px rgba(50, 52, 58, 0.1);
  height: 73px;

  @include respond-below(md) {
    padding: 0 8px;
    justify-content: space-between;
  }

  .ant-header-menu {
    padding-left: 85px;
    @include respond-below(md) {
      padding-left: 0px;
    }
  }

  .menu-burger {
    cursor: pointer;
  }
}

// menu

.ant-menu-horizontal {
  &.ant-menu-root {
    height: 100%;
    border-bottom: 0;
  }
  .ant-menu-title-content {
    a {
      position: relative;
      top: 22px;
      display: block;
      height: 63px;
      line-height: 30px;
    }
  }
}

.ant-dropdown .ant-dropdown-menu {
  padding: 16px;
}

// footer

.ant-layout-footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  color: $white-color;
  font-size: 12px;
  padding: 16px;
  background: linear-gradient(90deg, #4d5aa3 1.91%, #2c335b 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;

  a[href^='tel:'] {
    font-size: 14px;
    font-family: 'Roboto';
    color: $white-color;
    cursor: pointer;
    text-decoration: underline;
    vertical-align: middle;
    text-decoration: none;
  }

  a[href^='mailto:'] {
    font-size: 12px;
    color: $white-color;
    cursor: pointer;
    text-decoration: underline;
    text-decoration: none;
  }

  .ant-typography {
    color: $white-color;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
    font-size: 12px;
    line-height: 12px;
  }
}

// steps

.ant-steps-item {
  &.ant-steps-item-active {
    .ant-steps-item-container {
      .ant-steps-item-icon {
        &:before {
          background-color: #4e5ba6;
        }
      }
    }
  }
}

.ant-steps-item-tail {
  background-color: #f9fafb;
}

.ant-steps-item-icon {
  background-color: #f9fafb !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

  &:before {
    content: ' ';
    width: 8px;
    height: 8px;
    left: 4px;
    display: block;
    border-radius: 50%;
    position: relative;
    background-color: $grayed-border;
  }

  .ant-steps-icon {
    opacity: 0;
  }
}

.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: $grayed-border;
}

.ant-steps-item-title {
  color: white !important;
}
.ant-steps-item-description {
  color: white !important;
}

.ant-checkbox-inner,
.ant-checkbox-input {
  transform: scale(1.4);
}

.ant-btn-primary:disabled {
  border-color: #4e5ba659 !important;
}

// drawer

.ant-drawer-header-title {
  display: none !important;
}
.ant-drawer-header {
  display: flex;
  justify-content: flex-end;
  border-bottom: 0 !important;
}

@include respond-below(xs) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

// menu

.ant-menu-vertical {
  border-right: 0 !important;
}
