@import '../colors';
@import './functions.scss';

.rucap-input {
  width: 100%;
  height: 44px;
  margin-top: 0;
  border-radius: 4px;
  border-radius: 4px;
  padding: 10px 14px;
  border: 1px solid $light-border;

  &.PhoneInput > input {
    border: 0;
  }
}

.info-table {
  width: 100%;
  th {
    text-align: left;
    color: $gray-color;
    font-size: 16px;
    font-weight: normal;
    padding-bottom: 8px;

    &:first-child {
      font-weight: bold;
    }
  }

  td {
    &:not(:first-child) {
      font-weight: bold;
    }
  }
}

.hide-xs {
  @include respond-below(xs) {
    display: none !important;
  }
}
.visible-xs {
  @include respond-above(xs) {
    display: none !important;
  }
}