@import 'colors';

.detail-header {
  margin: 0;

  & .chevron-icon {
    margin-right: 8px;
  }
}

.backoffice-trade-detail-table {
  margin-top: 32px;

  & .main {
    color: $main-color;
  }

  & .fw-500 {
    font-weight: 500;
  }
}