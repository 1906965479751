@import 'colors';

.file-chosen {
  padding: 16px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  border: 1px solid $grayed-border;

  &.not-clickable {
    cursor: default;
  }

  img {
    margin-right: 16px;
  }

  .file-chosen-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .file-chosen-name {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: $label-color;
      letter-spacing: 0.02em;
    }

    .file-chosen-size {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $label-color;
    }
  }
}
