@import 'colors';

.client-details {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  background-color: $gray-background-color;
  border: 1px solid $grayed-border;
  border-radius: 12px;

  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-template-areas: 
    "fio inn phone email"
    "fond inn phone email";

  & .client-info-item {
    padding: 32px 16px;
  }

  & .client-info-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $gray-color;
    margin-bottom: 8px;
  }

  & .client-info-value {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $label-color;
  }

  & .fio {
    border-right: 1px dashed $grayed-border;
    border-bottom: 1px dashed $grayed-border;
  }

  & .fond {
    grid-area: fond;
    padding-left: 38px;
    border-right: 1px dashed $grayed-border;
  }

  & .inn, .phone {
    border-right: 1px dashed $grayed-border;
  }

  & .inn, .phone, .email {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  & .fio {
    grid-area: fio;
    padding-left: 38px;
  }

  & .phone {
    grid-area: phone;
  }

  & .email {
    grid-area: email;
  }

  & .inn {
    grid-area: inn;
  }
}

@media (max-width: 768px) {
  .client-details {
    grid-template-columns: 1fr;
    grid-template-areas: 
      "fio"
      "fond"
      "inn"
      "phone"
      "email";

    & .client-info-item {
      padding: 24px;
    }

    & .fio, .fond, .inn, .phone {
      border-right: none;
      border-bottom: 1px dashed $grayed-border;
    }

    & .inn, .phone, .email {
      display: block;
    }
  }
}
